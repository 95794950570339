// 领取详情 tableColumn
export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "promotionName",
        label: "活动名称",
    },
    {
        prop: "couponName",
        label: "优惠券名称",
    },
    {
        prop: "amount",
        label: "面值",
        width: 100,
    },
    {
        prop: "useRule",
        label: "使用条件",
        width: 100,
    },
    {
        prop: "date",
        label: "有效期",
        width: 200,
    },
    {
        prop: "leasorName",
        label: "领取人",
        width: 100,
    },
    {
        prop: "phone",
        label: "手机号",
        width: 150,
    },
    {
        prop: "createTime",
        label: "领取时间",
        width: 200,
    },
    {
        prop: "useDate",
        label: "使用时间",
        width: 200,
    },
    {
        prop: "status",
        label: "状态",
        width: 150,
    },
];